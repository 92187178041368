import { axiosInstance } from "../config/axios.config";
import { IProject } from "../interfaces/project.interface";

export class ProjectService {
  static getProjects(query?: string) {
    return axiosInstance.get("/projects.json");
  }

  static getProject(id: string): Promise<IProject> {
    return new Promise((res, rej) => {
      axiosInstance
        .get("/projects/" + id + ".json")
        .then((data) => {
          res({ ...data.data, id });
        })
        .catch((error) => rej(error));
    });
  }
}
