import { ISkill } from "./../../interfaces/skill.interface";
import "./skill.css";

export const Skill = (props: { skill: ISkill }) => {
  return (
    <div className="Skill">
      <div>
        <h2 style={{ margin: "0px" }}>{props.skill.name}</h2>
        <img
          src={props.skill.image}
          alt=""
          onError={(e: any) => {
            e.target.src = "https://img.icons8.com/ios/452/development-skill.png";
          }}
        />
      </div>
    </div>
  );
};
