import React from "react";
import "./App.css";
import { Profile } from "./profile/profile";
import { Skills } from "./skills/skills";
import { Projects } from "./projects/projects";
import { Contact } from "./contact/contact";

function App() {
  return (
    <div className="App">
      <Profile></Profile>
      <Projects />
      <Skills></Skills>
      <Contact></Contact>
    </div>
  );
}

export default App;
