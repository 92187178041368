import "./contact.css";
import React from "react";

export class Contact extends React.Component {
  state = { from_name: "", sender_email: "", message: "" };

  handleInput = (name: string, e: any) => {
    this.setState({ [name]: `${e.target.value}` });
  };
  async ValidateEmail(email: string) {
    return new Promise((resolve, reject) => {
      if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
        resolve(true);
      }
      reject({ message: "Invalid Email" });
    });
  }

  render() {
    return (
      <div>
        <h2 style={{ fontSize: "25px" }}>Contact :</h2>
        <form method="post" className="ContactView" name="contact" data-netlify="true">
          <input type="hidden" name="form-name" value="contact" />
          <div style={{ display: "flex" }}>
            <input type="text" placeholder="Name : -" required name="name" />
            <input type="email" placeholder="Email : -" required name="email" />
          </div>
          <div>
            <textarea name="message" required cols={30} rows={5} placeholder="message :-"></textarea>
          </div>
          <button type="submit">
            <h3 style={{ margin: "0px", color: "white" }}>Submit</h3>
          </button>
        </form>
      </div>
    );
  }
}
