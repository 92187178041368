import React from "react";
import { Project } from "./project/project";
import "./projects.css";
import { IProject } from "../interfaces/project.interface";
import { ProjectService } from "../services/project.service";
import { ProjectScore } from "../components/plateform.component";

export class Projects extends React.Component {
  state: { projects: IProject[] } = { projects: [] };

  componentDidMount() {
    ProjectService.getProjects()
      .then((data) => {
        const results: any[] = [];
        Object.keys(data.data).forEach((key) => results.push(Object.assign({}, { ...data.data[key], id: key })));
        console.log({ results });

        results.sort((a, b) => a.name - b.name);
        this.setState({ projects: results });
      })
      .catch((error) => console.log({ error }));
  }

  populateData = (id: string) => {
    ProjectService.getProject(id)
      .then((data) => console.log(data))
      .catch((error) => console.log({ error }));
  };

  render() {
    const projects = this.state.projects.map((p, index) => {
      return <Project key={index} details={p} onclick={() => this.populateData(p.id)}></Project>;
    });
    let projectsTab = null;
    let projectScore = null;
    const scoreBoardParameters = [
      { name: "Frontend Development", count: 0 },
      { name: "Backend Development", count: 0 },
      { name: "Mobile Development", count: 0 },
      { name: "Web Development", count: 0 },
    ];
    let newScore: { count: number; index: number; name: string }[] = [];
    scoreBoardParameters.forEach((p, index) => {
      const count = this.state.projects.filter((pr) => pr.role === p.name).length;
      newScore.push({ index, count, name: p.name });
    });

    if (projects.length > 0) {
      projectsTab = (
        <div>
          <div className="Projects">{projects}</div>
        </div>
      );
      projectScore = (
        <div className="ProjectScore">
          <ProjectScore details={newScore} />
        </div>
      );
    }
    return (
      <div>
        <h2 style={{ fontSize: "30px" }}>Projects :</h2>
        {projectScore}
        <br />
        {projectsTab}
      </div>
    );
  }
}
