import React from "react";
import profile from "./profile.jpeg";
import "./profile.css";
export class Profile extends React.Component {
  render() {
    const profileDescriptionStyle: React.CSSProperties = {
      fontSize: "50px",
      fontWeight: "bold",
      backgroundColor: "transparent",
      color: "white",
    };
    return (
      <div>
        <h2 style={{ fontSize: "25px" }}>About Me :</h2>
        <div className="Profile">
          <div className="ProfileDescription">
            <h2>
              &nbsp; &nbsp;&nbsp;&nbsp;I’m &nbsp; <mark style={profileDescriptionStyle}>Mailari Hulihond</mark>,&nbsp; &nbsp; Im from Bangalore , Since from
              2017, &nbsp; I’m working as Full Stack developer. have worked many projects and apart from that, I have been a freelancer.
            </h2>
            <br />
          </div>
          <div style={{ width: "200px", height: "200px" }}>
            <img src={profile} alt="" />
          </div>
        </div>
      </div>
    );
  }
}
