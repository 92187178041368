import React from "react";
import { IProject } from "../../interfaces/project.interface";
import "./project.css";

export const Project = (props: { details: IProject; onclick: Function }) => {
  return (
    <div className="Project" onClick={(event) => props.onclick(event)}>
      <h1 style={{ color: props.details.color }}>{props.details.name}</h1>
      <img src={props.details.image} alt="" />
      <h2>Role : {props.details.role}</h2>
    </div>
  );
};
