import { Component } from "react";
import { Skill } from "./skill/skill";
import { skills } from "./../assets/skills.json";
import { ISkill } from "../interfaces/skill.interface";
import "./skills.css";
export class Skills extends Component {
  state: { skillList: { frontEndTechnologies: ISkill[]; backEndTechnologies: ISkill[] } } = {
    skillList: { frontEndTechnologies: [], backEndTechnologies: [] },
  };

  componentDidMount() {
    this.setState({ skillList: skills });
  }

  render() {
    function getSkills(skills: ISkill[]) {
      return skills.map((skill, key) => <Skill skill={skill} key={key} />);
    }
    const skillHeadings = ["# Front-end Technologies", "# Back-end Technologies", "# Mobile Technologies", "# Database Technologies"];
    const allSkills = Object.values(this.state.skillList).map((item, key) => {
      return (
        <div key={key}>
          <h2 style={{ fontSize: "25px" }}>{skillHeadings[key]} : </h2>
          <div className="SkillsList">{getSkills(item)}</div>
        </div>
      );
    });
    return (
      <div>
        <h2 style={{ fontSize: "30px" }}>Skills :</h2>
        {allSkills}
      </div>
    );
  }
}
