import React from "react";

export const ProjectPlatform = (props: { details: { count: number; index: number; name: string } }) => {
  const images = [
    "https://firebasestorage.googleapis.com/v0/b/my-burger-d848a.appspot.com/o/frontend.png?alt=media&token=efbe83f5-22bc-4645-8122-9a9b516d115c",
    "https://firebasestorage.googleapis.com/v0/b/my-burger-d848a.appspot.com/o/backend.png?alt=media&token=44853566-4e04-4134-af98-dc864b5c57f8",
    "https://firebasestorage.googleapis.com/v0/b/my-burger-d848a.appspot.com/o/mobile.png?alt=media&token=40b1f33b-2fdb-48b1-956b-d216b3262e2f",
    "https://firebasestorage.googleapis.com/v0/b/my-burger-d848a.appspot.com/o/web.png?alt=media&token=0db4ffe2-318c-4284-b272-9bf595003dba",
  ];
  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
      <img style={{ height: "50px", width: "50px" }} src={images[props.details.index]} alt="" />
      <p style={{ margin: "1px" }}>{props.details.name}</p>
      <h2 style={{ margin: "0px" }}> {props.details.count}</h2>
    </div>
  );
};

export const ProjectScore = (props: { details: { count: number; index: number; name: string }[] }) => {
  const list = props.details.map((d, i) => <ProjectPlatform details={{ ...d }} key={i} />);
  return (
    <div
      style={{
        width: "100%",
        height: "90px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
      }}
    >
      {list}
    </div>
  );
};
